import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import FeatureOne from "@solid-ui-blocks/FeaturesWithPhoto/Block01";
import FeaturesInDotsWithPhoto from "@solid-ui-blocks/FeaturesWithPhoto/FeaturesInDotsWithPhoto";
import Tabs from "@solid-ui-components/Tabs";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import AppWrapper from '@helpers/appContext';
import theme from "./_theme";

const DeveloperSupport = (props) => {
  const { selectedLanguage } = props;
  const { allBlockContent } = useStaticQuery(query);

  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  const tabs = content[`tab-features.${selectedLanguage}`],
    toolsSectionOne = content[`tools-section-one.${selectedLanguage}`],
    toolsSectionTwo = content[`tools-section-two.${selectedLanguage}`];

  return (
    <Layout theme={theme} {...props}>
      <Seo title="Developers Support" />

      <Divider space="5" />
      <Divider space="5" />

      <Container variant="wide">
        <Tabs space={4}>
          {tabs.collection?.map((data) => (
            data?.collection?.length > 0 ?
              <FeaturesInDotsWithPhoto content={data} />
              :
              (data?.kicker?.text?.toLowerCase() === 'tools' ? (
                  <div content={data}> {/* passing data for tabs */}
                    <FeatureOne content={toolsSectionOne} />
                    <Divider space="2" />
                    <FeatureOne reverse content={toolsSectionTwo} />
                  </div>
                ):
                <FeatureOne content={data} />
              )
          ))}
        </Tabs>
      </Container>

      <Divider space="5" />
    </Layout>
  );
};

const query = graphql`
  query siteDevelopersSupportBlockContent {
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/developers-support/tabs"
            "site/developers-support/tools-section-one"
            "site/developers-support/tools-section-two"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

const DeveloperSupportPage = (props) => (
  <AppWrapper>
    <DeveloperSupport {...props}/>
  </AppWrapper>
);

export default DeveloperSupportPage;

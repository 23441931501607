import React from "react";
import { Container, Flex, Box, Heading } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import Reveal from "@solid-ui-components/Reveal";
import StackTitle from "@solid-ui-components/StackTitle";
import FlexImage from "@solid-ui-components/FlexImage";
import FlexContent from "@solid-ui-components/FlexContent";
import ContentImages from "@solid-ui-components/ContentImages";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import Divider from "@solid-ui-components/Divider";
import DotPic from "../../assets/dotPic.png";

const styles = {
  listItem: {
    alignItems: `stretch`,
    justifyContent: `flex-start`,
  },
};

const FeaturesInDotsWithPhoto = ({
  content: { kicker, title, text_1, collection, images }, reverse,
}) => (
  <Container>
    <Flex
      sx={{
        alignItems: [null, `center`],
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          reverse ? `row-reverse` : `row`,
        ],
        mx: [null, null, null, -4],
      }}
    >
      <FlexImage reverse={reverse}>
        <ContentImages
          content={{ images }}
          reverse={reverse}          
          imgStyle={{ borderRadius: '12px' }}
        />
      </FlexImage>
      <FlexContent reverse={reverse}>
        <StackTitle
          kicker={kicker}
          title={title}
          lines={[text_1]}
          align="left"
          space={0}
        />
        <Divider space="3" />
        {collection?.map(({ text_1 }, index) => (
          <Flex key={`item-${index}`} sx={styles.listItem}>
            <Box sx={{ position: `relative`, flexShrink: 0, mr: 4 }}>
              <Heading variant="h4" as="div" color="white">
                <img src={DotPic} alt="dot" />
              </Heading>
            </Box>
            <Box>
              <ContentText
                content={text_1}
                sx={{ maxWidth: 'auto' }}
                mb="4"
              />
            </Box>
          </Flex>
        ))}
      </FlexContent>
    </Flex>

  </Container>
);

export default WithDefaultContent(FeaturesInDotsWithPhoto);
